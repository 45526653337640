import { useMemo, useReducer } from "react";
import { TempStateType, cardTypeProp, templatesProps } from "src/types/temps";
import { TempContext } from "./template-context";
import { ActionMapType } from "src/types/auth";

type info = templatesProps
export type Payload = {
    ['UPLOAD']: {
        infos: info;
    };
    ['CARD-VERT']: {
        infos: info;
    };
    ['CARD-HORIZ']: {
        infos: info;
    };
    ['HUB-VERT']: {
        infos: info;
    };
    ['HUB-HORIZ']: {
        infos: info;
    };
    ['CARD-TYPE']: {
        cardType: cardTypeProp
    },
    ['default']: {
        infos: info
    }
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const reducer = (state: TempStateType, action: ActionsType) => {
    if (action.type === 'UPLOAD') {
        return {
            ...state,
            infos: {
                ...state.infos,
                ...action.payload.infos
            }
        }
    }
    if (action.type === 'CARD-HORIZ') {
        return {
            ...state,
            infos: {
                ...state.infos,
                ...action.payload.infos
            }
        }
    }
    if (action.type === 'CARD-VERT') {
        return {
            ...state,
            infos: {
                ...state.infos,
                ...action.payload.infos
            }
        }
    }
    if (action.type === 'CARD-TYPE') {
        return {
            ...state,
            cardType: action.payload.cardType
        }
    }
    if (action.type === 'default') {
        return {
            ...state,
            infos: {
                ...action.payload.infos
            }
        }
    }

    return state;
};

type Props = {
    children: React.ReactNode;
};

const initialTemplate: TempStateType = {
    infos: {} as info,
    cardType: cardTypeProp.HORIZ
}


/*
 -the template provider is used to store the data entered by the admin in the template 
 -the cardType is a property that will be used to determine the type of card(horizontal or vertical) that admin deel with
 */
export default function TempProvider({ children }: Props) {

    const [state, dispatch] = useReducer(reducer, initialTemplate);

    const memoizedValue = useMemo(
        () => ({
            infos: state.infos,
            cardType: state.cardType,
            dispatch,
        }),
        [dispatch, state.infos, state.cardType]
    );

    return <TempContext.Provider value={memoizedValue}>
        {children}
    </TempContext.Provider>;
}
