import { Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";
import SplashScreen from "src/components/loading-screen/SplashScreen";
import TempProvider from "src/context/template-provider";
import AuthGuard from "src/guards/auth-guard";
import RoleBasedGuard from "src/guards/role-based-guard";
import DashboardLayout from "src/layouts/dashboard/index";
// import DashboardLayout from "src/layouts/dashboardoo/dash-layout";


const AdminDash = lazy(() => import('src/pages/dashborad/dash-admin'));
const Business_title = lazy(() => import('src/pages/dashborad/dash-titles'));
const BusinessTemplates = lazy(() => import('src/pages/dashborad/dash-templates'));
const Business_Edit_Templates = lazy(() => import('src/pages/dashborad/dash-edit-template'));
const AllTemplatesPages = lazy(() => import('src/pages/dashborad/dash-all-templates'));
const CustomerCardsPage = lazy(() => import('src/pages/dashborad/dash-customer-cards'));
const BrowseCustomersPage = lazy(() => import('src/pages/dashborad/dash-browse-customers'));
export const dashAdminRoutes = [
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <RoleBasedGuard roles={['ADMIN']}>
                    <DashboardLayout>
                        <Suspense fallback={<SplashScreen />}>
                            <Outlet />
                        </Suspense>
                    </DashboardLayout>
                </RoleBasedGuard>
            </AuthGuard>
        ),
        children: [
            {
                element: <AdminDash />,
                index: true
            },
            {
                path: 'titles',
                element: (
                    <Business_title />
                )
            },
            {
                path: 'templates',
                element: (
                    <TempProvider>
                        <BusinessTemplates />
                    </TempProvider>
                )
            },
            {
                path: 'templates/:id',
                element: (
                    <TempProvider>
                        <Business_Edit_Templates />
                    </TempProvider>
                )
            },
            {
                path: 'allTemplates',
                element: (
                    <TempProvider>
                        <AllTemplatesPages />
                    </TempProvider>
                )
            },
            {
                path: 'customerCards',
                element: (
                    <TempProvider>
                        <CustomerCardsPage />
                    </TempProvider>
                )
            },
            {
                path: 'browseCustomers',
                element: (
                    <BrowseCustomersPage />
                )
            },
            {
                path: '*',
                element: (
                    <>
                        <AdminDash />
                    </>
                )
            }
        ]
    }
]