import { useMemo } from 'react';
import useSWR from 'swr';

import { fetcher, endpoints } from 'src/utils/axios';


// ----------------------------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetAdminInfo(id: string): any {
  const URL = `${endpoints.dashboard.admin.my_info}/${id}`
  const { data, isLoading, error, isValidating } = useSWR(id ? URL : null, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      admin: data || {},
      Loading: isLoading,
      error,
      isValidating: isValidating
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

