import { m } from 'framer-motion';
import { useCallback } from 'react';

import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { useLocales, useTranslate } from 'src/locales';
import Iconify from 'src/components/iconify/Iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { Button } from '@mui/material';


// this component is used to display the language popover(dropdown menu with all the languages)
export default function LanguagePopover() {
    const popover = usePopover();

    const { onChangeLang } = useTranslate();

    const { allLangs, currentLang } = useLocales();

    const handleChangeLang = useCallback(
        (newLang: string) => {
            onChangeLang(newLang);
            popover.onClose();
        },
        [onChangeLang, popover]
    );
    return (
        <>
            <Button
                variant='outlined'
                onClick={() => handleChangeLang(currentLang.value === 'en' ? 'ar' : 'en')}
                startIcon={<Iconify
                    icon='jam:world'
                />}
                sx={{
                    color: 'text.secondary',
                    borderColor: 'text.secondary',
                    '&:hover': {
                        color: 'primary.dark',
                        borderColor: 'primary.dark',
                    },
                    borderRadius: '20px'
                }}
            >
                {currentLang.label}
            </Button>
            {/* <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                onClick={popover.onOpen}
                sx={{
                    width: 40,
                    height: 40,
                    ...(popover.open && {
                        bgcolor: 'action.selected',
                    }),
                }}
            >
                <Iconify icon={currentLang.icon} sx={{ borderRadius: 0.65, width: 28 }} />
            </IconButton>

            <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
                {allLangs.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === currentLang.value}
                        onClick={() => handleChangeLang(option.value)}
                    >
                        <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} />

                        {option.label}
                    </MenuItem>
                ))}
            </CustomPopover> */}
        </>
    );
}
