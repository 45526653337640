import { Button, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getLimitedCards } from "src/api/auth/plan-info";
import ConfirmDialog from "src/components/custom-dialog/confirm-dialog";
import Iconify from "src/components/iconify/Iconify";
import SplashScreen from "src/components/loading-screen/SplashScreen";
import { useSettingsContext } from "src/components/settings";
import { useBoolean } from "src/hooks/use-boolean";
import { useTranslate } from "src/locales";
import { paths } from "src/routes/paths";


type Props = {
    children: React.ReactNode
}

/*
    this plan guard is used to check if the customer reached the maximum amount of cards 
    if yes he will get a popup to upgrade to the business plan,
    if not he will continue to the dashboard
*/
export default function PlanGuard({ children }: Props) {

    const [checked, setChecked] = useState(false);

    const [error, setError] = useState("");

    const loading = useBoolean(true);

    // callback to see if the user reached the maximum amount of cards
    const initialize = useCallback(() => {
        getLimitedCards().then(({ error, isValidating }) => {
            if (error) {
                setChecked(false);
                setError(error);
            } else {
                setChecked(true);
            }
            loading.onFalse()
        })
    }, [])

    useEffect(() => {
        initialize()
    }, [initialize])

    if (loading.value) return <SplashScreen />

    if (!loading.value && !checked) {
        return (
            <>
                {children}
                <PopupForLimitedCards error={error} />
            </>
        )
    }

    return <>
        {children}
    </>
}



// popup to upgrade to the business plan
const PopupForLimitedCards = (
    { error }: { error?: string }
) => {

    const { t } = useTranslate()

    const setting = useSettingsContext();

    const isRTL = setting.themeDirection === 'rtl'
    return (
        <ConfirmDialog
            open={true}
            cancel={false}
            maxWidth='xs'
            onClose={() => { }}
            title={!!error && t(error)}
            content={<Stack direction={{ xs: 'column', lg: 'row' }} spacing={{ xs: 1, md: 2 }} justifyContent='space-between'>
                <Button
                    variant='outlined'
                    color='warning'
                    startIcon={<Iconify icon={`mdi:arrow-${isRTL ? 'right' : 'left'}`} width={16} />}
                    href={paths.dashboard.customer.home}
                >
                    {t('common.goToDashboard')}
                </Button>
                <Button
                    variant='contained'
                    disabled
                >
                    {t('common.proPlanIsComming')}
                </Button>
            </Stack>}
        />
    )
}

