import { useCallback, useEffect, useMemo, useReducer } from "react";
import { ActionMapType } from "src/types/auth";
import { IUserItem, IUserStateType } from "src/types/users";
import { CustomerInfosContext } from "./customer-infos-context";
import { useGetCustomer } from "src/api/customer/customer-infos";
import { GetAvatarUser } from "src/api/business/business-card";
import { enqueueSnackbar } from "notistack";

export type Payload = {
    ['CURRENTUSER']: {
        currentUser: IUserItem
    };
    ['AVATAR']: {
        avatar: string
    }
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const reducer = (state: IUserStateType, action: ActionsType) => {
    if (action.type === 'CURRENTUSER') {
        return {
            ...state,
            currentUser: action.payload.currentUser,
            loading: false
        }
    }

    if (action.type === 'AVATAR') {
        return {
            ...state,
            avatar: action.payload.avatar,
            loading: false
        }
    }

    return state;
};

type Props = {
    children: React.ReactNode;
};

const initialUser: IUserStateType = {
    currentUser: {} as IUserItem,
    loading: true,
    avatar: ''
}

export default function CustomerInfosProvider({ children }: Props) {

    const [state, dispatch] = useReducer(reducer, initialUser);

    const { customer, Loading, error, mutate } = useGetCustomer();

    const fetchAvatar = useCallback(async () => {
        if (customer?.id) {
            try {
                const { error, avatar: avatarUser = '' } = await GetAvatarUser(customer.id);
                if (error) {
                    enqueueSnackbar(error, { variant: 'error' });
                }
                else {
                    dispatch({
                        type: 'AVATAR',
                        payload: {
                            avatar: avatarUser
                        }
                    })
                }
            } catch (err) {
                enqueueSnackbar('Failed to fetch avatar', { variant: 'error' });
            }
        }
    }, [customer]);

    const initialize = useCallback(async () => {
        if (!Loading && Object.keys(customer).length > 0) {
            dispatch({
                type: 'CURRENTUSER',
                payload: {
                    currentUser: customer
                }
            })
            fetchAvatar();
        }

    }, [customer, Loading]);

    useEffect(() => {
        initialize();
    }, [initialize]);


    const memoizedValue = useMemo(
        () => ({
            currentUser: state.currentUser,
            loading: state.loading,
            avatar: state.avatar,
            mutate,
        }),
        [state.currentUser, state.loading, state.avatar, mutate]
    );

    if (error) return <div>something went wrong</div>;

    return <CustomerInfosContext.Provider value={memoizedValue}>
        {children}
    </CustomerInfosContext.Provider>;
}
