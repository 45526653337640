import { useMemo } from 'react';
// import merge from 'lodash/merge';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeOptions, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';


// system
import { palette } from './palette';
import { typography } from './typography';
import { useSettingsContext } from 'src/components/settings';
import RTL from './right-to-left';
import { localStorageGetItem } from 'src/utils/storage-available';
import getFontFamily from 'src/utils/get-fontfamily';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
    const settings = useSettingsContext();

    const language = localStorageGetItem('i18nextLng') || 'en';

    const memoizedValue = useMemo(
        () => ({
            palette: {
                ...palette(settings.themeMode),
            },
            direction: settings.themeDirection,
            shape: { borderRadius: 8 },
            typography: {
                ...typography,
                fontFamily: getFontFamily(language),
            },
            components: {
                MuiAvatar: {
                    styleOverrides: {
                        img: {
                            objectFit: 'contain',
                        },
                    },
                },
            },
        }),
        [
            settings.themeDirection,
            language,
            // must add setting later
        ]
    );

    const theme = createTheme(memoizedValue as ThemeOptions);

    // theme.components = componentsOverrides(theme);

    return (
        <MuiThemeProvider theme={theme}>
            <RTL themeDirection={settings.themeDirection}>
                <CssBaseline />
                {children}
            </RTL>
        </MuiThemeProvider>
    );
}
