import { Suspense, lazy } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import SplashScreen from 'src/components/loading-screen/SplashScreen'
import GuestGuard from 'src/guards/guest-guard'
import AuthLayout from 'src/layouts/auth-layout'
import VerifyLayout from 'src/layouts/verification/verify-layout'


const RegisterPage = lazy(() => import('src/pages/auth/register'));
const LoginPage = lazy(() => import('src/pages/auth/login-page'));
const VerifyPage = lazy(() => import('src/pages/auth/verify-page'));
const CheckPage = lazy(() => import('src/pages/auth/check-page'));
const ForgetPage = lazy(() => import('src/pages/auth/forget-page'));
const ResetPwdPage = lazy(() => import('src/pages/auth/reset-page'));
export const authRoutes = [
    {
        path: 'auth',
        element: (
            <GuestGuard>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </GuestGuard>
        ),
        children: [
            {
                index: true,
                element: <Navigate to="/auth/login" replace />
            },
            {
                element: (
                    <AuthLayout>
                        <Outlet />
                    </AuthLayout>
                ),
                children: [
                    { path: 'register', element: <RegisterPage /> },
                    { path: 'login', element: <LoginPage /> }
                ]
            },
            {
                element: (
                    <VerifyLayout>
                        <Outlet />
                    </VerifyLayout>
                ),
                children: [
                    { path: 'verify', element: <VerifyPage /> },
                    { path: 'check/:token', element: <CheckPage /> },
                    { path: 'forgetPassword', element: <ForgetPage /> },
                    { path: 'forget/:token', element: <ResetPwdPage /> },
                ]
            }
        ]
    }
]