import { Navigate } from 'react-router-dom';
import { useAuthContext } from 'src/auth/hooks/use-auth-context';

type RoleBasedGuardProps = {
    roles?: string[];
    children: React.ReactNode;
};


/*
   -this guard is used to check if the user is admin or not
   -note: the admin infos stored in local storage contain the Role field unlike the user infos(does not contain the Role field)
*/
export default function RoleBasedGuard({ roles = [], children }: RoleBasedGuardProps) {
    const { user } = useAuthContext();

    if (!user?.Role || (roles.length && !roles.includes(user.Role))) {
        return <Navigate to="/404" />;
    }

    return <>{children}</>;
}
