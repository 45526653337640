import { AxiosRequestConfig } from "axios";
import { useMemo } from "react";
import axiosInstance, { endpoints } from "src/utils/axios";
import { errorProcess } from "src/utils/error-process";
import useSWR from "swr";

const options = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
};


type returnProps = {
    loading: boolean,
    error: any,
    isValidating: any,
}
export async function getLimitedCards(): Promise<returnProps> {
    const URL = `${endpoints.dashboard.customer.plan.maxCards}`;
    const accessToken = JSON.parse(localStorage.getItem('infos') || '{}').refreshToken || '';
    const axios = axiosInstance;

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const memoizedValue = {
        loading: true,
        error: null,
        isValidating: false
    };
    try {
        const res = await axios.get(URL);
        memoizedValue.loading = false;
        memoizedValue.isValidating = !!res.data;
    } catch (error: any) {
        const errMsg = errorProcess(error)
        memoizedValue.loading = false;
        memoizedValue.error = errMsg;
    }
    return memoizedValue

}