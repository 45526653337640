import { useEffect, useCallback, useState } from 'react';

import { paths } from 'src/routes/paths';
import SplashScreen from 'src/components/loading-screen/SplashScreen';
import { useAuthContext } from 'src/auth/hooks/use-auth-context';
import { useRouter } from 'src/hooks/use-router';




type Props = {
  children: React.ReactNode;
};

/*
   when the user is logged in and he want to go to login page he will be redirected to the home page
   until he logout
*/
export default function GuestGuard({ children }: Props) {
  const { loading } = useAuthContext();

  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const router = useRouter();

  const returnTo = paths.home.index;

  const [checked, setChecked] = useState<boolean>(false);

  const { authenticated } = useAuthContext();

  const check = useCallback(() => {
    if (authenticated) {
      router.replace(returnTo);
    }
    setChecked(true);
  }, [authenticated, returnTo, router]);

  useEffect(() => {
    check();
  }, [check]);

  if (!checked) return null;

  return <>{children}</>;
}
