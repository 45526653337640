import { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "src/auth/hooks/use-auth-context";
import SplashScreen from "src/components/loading-screen/SplashScreen";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/routes/paths";

type Props = {
    children: React.ReactNode;
};

/*
  this is a guard that will be used to check if the user is authenticated or not 
  (is he logged in or not) and if he is not authenticated it will redirect him to the login page
  if he is authenticated it will render the children
*/
export default function AuthCustomerGuard({ children }: Props) {
    const { loading } = useAuthContext();

    return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

function Container({ children }: Props) {
    const router = useRouter();
    const { authenticated } = useAuthContext();
    const [checked, setChecked] = useState(false);
    const check = useCallback(() => {
        if (!authenticated) {
            const loginPath = paths.auth.login

            const href = `${loginPath}`;

            router.replace(href);
        } else {
            setChecked(true);
        }
    }, [authenticated, router]);

    useEffect(() => {
        check();
    }, [check]);


    if (!checked) {
        return null
    }


    return <>{children}</>
}