import { Suspense, lazy } from 'react'
import { Outlet } from 'react-router-dom'
import SplashScreen from 'src/components/loading-screen/SplashScreen'
import CustomerInfosProvider from 'src/context/customer-infos-provider'
import DigitalPreviewProvider from 'src/context/digital-preview-provider'
import AuthCustomerGuard from 'src/guards/auth-customer-guard'
import DashboardGuard from 'src/guards/dashboard-guard'
import PlanGuard from 'src/guards/plan-guard'
import DashboardCustomerLayout from 'src/layouts/dash-customer/index'


const CustomerDashboardPage = lazy(() => import('src/pages/customer-dash/customer-dash-page'));
const CustomerDigitalPage = lazy(() => import('src/pages/customer-dash/customer-digital-page'));
const CustomerEditDigitalPage = lazy(() => import('src/pages/customer-dash/customer-edit-digital-page'));
const CustomerAccountPage = lazy(() => import('src/pages/customer-dash/customer-account-page'));
const DigitalHubPreviewPage = lazy(() => import('src/pages/digital-hub/digital-hub-preview-page'));
export const customerRoutes = [
    {
        path: 'customer',
        element: (
            <AuthCustomerGuard>
                <DashboardCustomerLayout>
                    <Suspense fallback={<SplashScreen />}>
                        <Outlet />
                    </Suspense>
                </DashboardCustomerLayout>
            </AuthCustomerGuard>
        ),
        children: [
            {
                index: true,
                element: (
                    <DashboardGuard>
                        <CustomerDashboardPage />
                    </DashboardGuard>
                )
            },
            {
                path: 'create',
                element: (
                    <PlanGuard>
                        <DigitalPreviewProvider>
                            <CustomerDigitalPage />
                        </DigitalPreviewProvider>
                    </PlanGuard>
                )
            },
            {
                element: (
                    <DigitalPreviewProvider>
                        <Outlet />
                    </DigitalPreviewProvider>
                ),
                children: [
                    { path: "edit/:id", element: <CustomerEditDigitalPage /> },
                    { path: "edit/:id/:templateId", element: <CustomerEditDigitalPage /> },
                    { path: "preview/:cardName", element: <DigitalHubPreviewPage /> }
                ]
            },
            {
                path: 'Account',
                element: (
                    <CustomerInfosProvider>
                        <Outlet />
                    </CustomerInfosProvider>
                ),
                children: [
                    { index: true, element: <CustomerAccountPage /> },
                ]
            }
        ]
    }
]