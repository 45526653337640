import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';


import { useResponsive } from 'src/hooks/use-responsive';

import Scrollbar from 'src/components/scrollbar';

import { useNavData } from './config-navigation';
import NavToggleButton from '../common/nav-toggle-button';
import { usePathname } from 'src/hooks/use-pathname';
import Logo from 'src/components/logo/logo';
import { NavSectionVertical } from 'src/components/nav-section';
import { NAV } from 'src/hooks/use-off-set-top';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};


//This is the navigation for the admin dashboard in a vertical layout (when the navigation expands to a wider width).
export default function NavVertical({ openNav, onCloseNav }: Props) {

  const settings = useSettingsContext();

  const isRTL = settings.themeDirection === 'rtl';

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo
        variant='large'
        sx={{ transform: "scale(1.2)", mt: 3, ml: 4, mb: 1 }} />

      <NavSectionVertical
        data={navData}
      />

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          SlideProps={{
            direction: isRTL ? 'left' : 'right',
          }}
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
