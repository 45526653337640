import Box from '@mui/material/Box';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSettingsContext } from 'src/components/settings';
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
import { Chip } from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import { useTranslate } from 'src/locales';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

// this layout is created for the customer dashboard
export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>
            {children}
            <SuggestionComponent />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>
          {children}
          <SuggestionComponent />

        </Main>
      </Box>
    </>
  );
}

const SuggestionComponent = () => {
  const { t } = useTranslate();
  return (
    <Chip
      label={t('common.suggestions')}
      component="a"
      href={process.env.REACT_APP_SUGGESTION_URL || ''}
      target='_blank'
      clickable
      icon={
        <Iconify
          color='common.white'
          icon="hugeicons:idea-01"
          width={{ xs: '1.5em', md: '1.5rem' }}
          height={{ xs: '1.5rem', md: '1.5rem' }}
        />
      }
      sx={{
        position: 'fixed',
        height: 37,
        bottom: 20,
        right: 15,
        zIndex: 10,
        bgcolor: 'primary.main',
        fontSize: (theme) => theme.typography.body1.fontSize,
        fontWeight: (theme) => theme.typography.fontWeightMedium,
        color: 'common.white',
        '&:hover': {
          bgcolor: 'primary.dark',
        },
      }}
    />
  )
}

