import { useState, useEffect, useCallback } from 'react';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';


import Scrollbar from 'src/components/scrollbar';

import NavList from './nav-list';
import { NavProps } from '../types';
import { usePathname } from 'src/hooks/use-pathname';
import Logo from 'src/components/logo/logo';
import Iconify from 'src/components/iconify/Iconify';
import { useSettingsContext } from 'src/components/settings';
import LanguagePopover from 'src/layouts/common/language-popover';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function NavMobile({ data }: NavProps) {

  const settings = useSettingsContext();

  const isRTL = settings.themeDirection === 'rtl';

  const pathname = usePathname();

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  return (
    <>
      <IconButton
        size='large'
        onClick={handleOpenMenu} sx={{ ml: 1 }}
      >
        <Iconify icon='mdi:menu' />
        {/* <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" /> */}
      </IconButton>

      <Drawer
        open={openMenu}
        onClose={handleCloseMenu}
        SlideProps={{
          direction: isRTL ? 'left' : 'right',
        }}
        PaperProps={{
          sx: {
            pb: 5,
            width: 260,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />
          <Box m={2}>
            <LanguagePopover />
          </Box>

          {data.map((list) => (
            <NavList key={list.title} data={list} />
          ))}
        </Scrollbar>
      </Drawer>
    </>
  );
}
