import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { hideScroll } from 'src/theme/css';
import { NavSectionMini } from 'src/components/nav-section';
import { NAV } from 'src/hooks/use-off-set-top';
import Logo from 'src/components/logo/logo';
import NavToggleButton from '../common/nav-toggle-button';
import { useNavCustomerDashboard } from '../dashboard/config-navigation';
import { IconButton } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks/use-auth-context';
import { jwtDecode } from 'src/utils/auth-utils';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/routes/paths';
import { errorProcess } from 'src/utils/error-process';
import { enqueueSnackbar } from 'notistack';
import Iconify from 'src/components/iconify/Iconify';

// ----------------------------------------------------------------------


// this is the nav for the customer dashboard in a mini layout (when the nav becomes mini after touching the nav toggle button)
export default function NavMini() {

  const navData = useNavCustomerDashboard();

  const { user, logout } = useAuthContext();

  const decodedToken = jwtDecode(user?.refreshToken || '');

  const router = useRouter()

  const handleLogout = async () => {
    let path;
    try {
      if (!!decodedToken?.Role) {
        path = paths.admin.login
      } else {
        path = paths.auth.login
      }
      await logout();
      router.push(path);
    } catch (error) {
      const errMsg = errorProcess(error);
      enqueueSnackbar(errMsg, {
        variant: 'error'
      })
    }
  };

  const renderBtnLogout = (
    <IconButton
      size='large'
      sx={{ width: 'max-content', mx: 'auto' }}
      onClick={handleLogout}
    >
      <Iconify icon='humbleicons:logout' />
    </IconButton>
  )


  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Logo
          variant='large'
          sx={{ mx: 'auto', my: 2 }}
        />

        <NavSectionMini
          data={navData}
        />

        <Box sx={{ flexGrow: 1 }} />
        {renderBtnLogout}
      </Stack>
    </Box>
  );
}
