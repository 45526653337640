import { useScroll } from 'framer-motion';
import { useMemo, useState, useEffect, useCallback } from 'react';

// ----------------------------------------------------------------------

type ReturnType = boolean;

interface UseScrollOptions extends Omit<ScrollOptions, 'container' | 'target'> {
    container?: React.RefObject<HTMLElement>;
    target?: React.RefObject<HTMLElement>;
}

// this hook is used to check if the user is scrolling from the top of the page
export function useOffSetTop(top = 0, options?: UseScrollOptions): ReturnType {
    const { scrollY } = useScroll(options);

    const [value, setValue] = useState(false);

    const onOffSetTop = useCallback(() => {
        scrollY.on('change', (scrollHeight) => {
            if (scrollHeight > top) {
                setValue(true);
            } else {
                setValue(false);
            }
        });
    }, [scrollY, top]);

    useEffect(() => {
        onOffSetTop();
    }, [onOffSetTop]);

    const memoizedValue = useMemo(() => value, [value]);

    return memoizedValue;
}

// ----------------------------------------------------------------------

export const HEADER = {
    H_MOBILE: 64,
    H_DESKTOP: 80,
    H_DESKTOP_OFFSET: 80 - 16,
};

export const NAV = {
    W_VERTICAL: 260,
    W_MINI: 88,
};
