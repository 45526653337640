import { useAuthContext } from "src/auth/hooks/use-auth-context";
import SplashScreen from "src/components/loading-screen/SplashScreen";

type Props = {
    children: React.ReactNode;
};

export default function HomeGuard({ children }: Props) {
    const { loading } = useAuthContext();

    return <>{loading ? <SplashScreen /> : <>{children}</>}</>;
}