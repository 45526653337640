import { useState, useEffect, useCallback } from 'react';
import { useGetAdminInfo } from 'src/api/admin/admin-info';
import { Types } from 'src/auth/context/auth-provider';
import { useAuthContext } from 'src/auth/hooks/use-auth-context';
import SplashScreen from 'src/components/loading-screen/SplashScreen';
import { useRouter } from 'src/hooks/use-router';
import { useSearchParams } from 'src/hooks/use-search-params';
import { paths } from 'src/routes/paths';

// Main component
type Props = {
    children: React.ReactNode;
};

/*
  this is a guard that will be used to check if the admin is authenticated or not
  (is he logged in or not) and if he is not authenticated it will redirect him to the login page
  if he is authenticated it will render the children

*/
export default function AuthGuard({ children }: Props) {
    const { loading } = useAuthContext();

    return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// Container component
function Container({ children }: Props) {

    const router = useRouter();

    const searchParams = useSearchParams();

    const { authenticated, dispatch } = useAuthContext();

    const [checked, setChecked] = useState(false);

    // i do this fetching(useGetAdminInfo) in case of the admin logging with google 
    const { Loading, admin, error } = useGetAdminInfo(searchParams.get('id') ?? '');

    const checkAuthentication = useCallback(() => {
        if (!authenticated) {
            const loginPath = paths.admin.login

            const href = `${loginPath}`;

            router.replace(href);
        } else {
            setChecked(true);
        }
    }, [router, authenticated]);

    useEffect(() => {
        // if he don't get the id in case of google login it will simply check his authentication with the credentials
        if (!searchParams.get('id')) {
            checkAuthentication();
            return;
        }

        if (error) {
            router.replace(paths.dashboard.admin.home);
            return;
        }

        if (!Loading) {
            localStorage.setItem('infos', JSON.stringify(admin));
            dispatch({ type: Types.REGISTER, payload: { user: { ...admin } } });
            setChecked(true);
        }
    }, [searchParams, error, Loading, admin, dispatch, router]);

    if (!checked) {
        return null;
    }

    return <>{children}</>;
}
