import { Suspense, lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthLayout from "src/layouts/auth-layout";
import VerifyLayout from "src/layouts/verification/verify-layout";


const AdminLoginPage = lazy(() => import('src/pages/auth/admin-login'));
const AdminVerifyPage = lazy(() => import('src/pages/auth/admin-verify-page'));
const AdminCheckPage = lazy(() => import('src/pages/auth/admin-check-page'));
export const adminRoutes = [
    {
        path: 'admin',
        element: (
            <Suspense>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                index: true,
                element: <Navigate to="/admin/login" replace />
            },
            {
                path: 'login',
                element: (
                    <AuthLayout>
                        <AdminLoginPage />
                    </AuthLayout>
                )
            },
            {
                element: (
                    <VerifyLayout>
                        <Outlet />
                    </VerifyLayout>
                ),
                children: [
                    { path: 'verify', element: <AdminVerifyPage /> },
                    { path: 'check/:token', element: <AdminCheckPage /> },
                ]

            }
        ]
    }
]