import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { useResponsive } from 'src/hooks/use-responsive';
import Scrollbar from 'src/components/scrollbar';
import NavToggleButton from '../common/nav-toggle-button';
import { usePathname } from 'src/hooks/use-pathname';
import Logo from 'src/components/logo/logo';
import { NavSectionVertical } from 'src/components/nav-section';
import { NAV } from 'src/hooks/use-off-set-top';
import { useSettingsContext } from 'src/components/settings';
import { useNavCustomerDashboard } from '../dashboard/config-navigation';
import Iconify from 'src/components/iconify/Iconify';
import { Button, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslate } from 'src/locales';
import { useRouter } from 'src/hooks/use-router';
import { jwtDecode } from 'src/utils/auth-utils';
import { useAuthContext } from 'src/auth/hooks/use-auth-context';
import { paths } from 'src/routes/paths';
import { errorProcess } from 'src/utils/error-process';
import { enqueueSnackbar } from 'notistack';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};


//This is the navigation for the customer dashboard in a vertical layout (when the navigation expands to a wider width).
export default function NavVertical({ openNav, onCloseNav }: Props) {

  const { t } = useTranslate();

  const settings = useSettingsContext();

  const isRTL = settings.themeDirection === 'rtl';

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavCustomerDashboard();

  const { user, logout } = useAuthContext();

  const decodedToken = jwtDecode(user?.refreshToken || '');

  const router = useRouter()

  const handleLogout = async () => {
    let path;
    try {
      if (!!decodedToken?.Role) {
        path = paths.admin.login
      } else {
        path = paths.auth.login
      }
      await logout();
      router.push(path);
    } catch (error) {
      const errMsg = errorProcess(error);
      enqueueSnackbar(errMsg, {
        variant: 'error'
      })
    }
  };


  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo
        variant='large'
        sx={{ transform: "scale(1.2)", my: 3, ml: 4 }}
      />

      <NavSectionVertical
        data={navData}
      />


      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  const renderBtnLogout = (
    <Button
      variant='outlined'
      color='inherit'
      onClick={handleLogout}
      startIcon={<Iconify icon='humbleicons:logout' />}
      sx={{ m: 2, color: 'text.secondary' }}
    >
      {t('dashboard.header.logout')}
    </Button>
  )

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
          {renderBtnLogout}
        </Stack>
      ) : (
        <Drawer
          SlideProps={{
            direction: isRTL ? 'left' : 'right',
          }}
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
          {renderBtnLogout}
        </Drawer>
      )}
    </Box>
  );
}
