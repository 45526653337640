import 'src/global.css';
import { AuthProvider } from "./auth/context/auth-provider";
import SnackbarProvider from "./components/snackbar/snackbar-provider";
import Router from "./routes";
import ThemeProvider from "./theme";
import { SettingsProvider } from './components/settings';
import 'src/locales/i18n';
import { MotionLazy } from './components/animate/motion-lazy';
import { useScrollToTop } from './hooks/use-scroll-to-top';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function App() {
  useScrollToTop();
  return (
    <>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  <Router />
                </SnackbarProvider>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </AuthProvider>
    </>
  );
}

export default App;
