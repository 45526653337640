import { Button, Card, Skeleton, Stack, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { createContext, useCallback, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useGetCustomer } from "src/api/customer/customer-infos";
import { useGetAllMyTemplates } from "src/api/templates/template-customer";
import WelcomeIcon from "src/assets/icons/welcome-icon";
import { useAuthContext } from "src/auth/hooks/use-auth-context";
import SplashScreen from "src/components/loading-screen/SplashScreen";
import { useTranslate } from "src/locales";
import { paths } from "src/routes/paths";
import AppWelcome from "src/sections/customer/dashboard/app-welcome";
import { IMCardsItems, cardTypeProp } from "src/types/temps";
import axiosInstance from "src/utils/axios";

type Props = {
    children: React.ReactNode;
};

type cardContextProp = {
    cards: IMCardsItems[],
    cardType: cardTypeProp,
    setCardType: React.Dispatch<React.SetStateAction<cardTypeProp>>,
    mutate: any
}

export const CardContext = createContext({} as cardContextProp);


/*
  this is a guard that will be used to check if the customer has a business card or not
  if he has--->  he will see all his cards 
  if he doesn't have---> he will see the welcome page
*/
export default function DashboardGuard({ children }: Props) {

    // load the cards of the user

    const { pathname } = useLocation();

    const isDashboard = !!matchPath({ end: true, path: paths.dashboard.customer.home }, pathname);


    const { cards, Loading, error, mutate } = useGetAllMyTemplates();

    useEffect(() => {
        if (!isDashboard) return;
        mutate();
    }, [isDashboard, mutate]);
 
    // cardType is a state that determine the type of the card that the customer choose(by default it is horizontal)
    const [cardType, setCardType] = useState<cardTypeProp>(cardTypeProp.HORIZ)

    if (Loading) return <SplashScreen />

    if (error) return <h3>something went wrong</h3>

    if (Array.isArray(cards) && cards.length === 0) return <Container />

    return <>
        <CardContext.Provider value={{ cards, cardType, setCardType, mutate }}>
            {children}
        </CardContext.Provider>
    </>
}


// this is the welcome page
function Container() {

    const { t } = useTranslate();

    const { customer, Loading, error } = useGetCustomer();

    const renderHeader = (
        <Typography
            variant='h4'
            gutterBottom
        >
            {t('dashboard.sideBar.Account Dashboard')}
        </Typography>
    )

    const renderLoading = (
        <Card sx={{ maxHeight: 300, bgcolor: 'grey.A100', display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 5 }}>
            <Stack spacing={2}>
                <Skeleton animation='wave' variant="rectangular" sx={{ width: { xs: 100, sm: 200 }, height: 30, borderRadius: 10 }} />
                <Skeleton animation='wave' variant="rectangular" sx={{ width: { xs: 150, sm: 300 }, height: 30, borderRadius: 10 }} />
                <Skeleton animation='wave' variant="rounded" sx={{ width: { xs: 80, md: 120 }, height: 35 }} />
            </Stack>
            <Skeleton
                animation='wave'
                variant="rounded"
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    width: 200,
                    height: 150
                }}
            />

        </Card>
    )

    if (error) return <h3>something went wrong</h3>
    return (
        <Stack spacing={3}>
            {renderHeader}
            {
                Loading ? renderLoading :
                    <AppWelcome
                        title={`${t('dashboard.Account Dashboard.welcomeTitle')}, ${customer.firstName} ${customer.lastName}`}
                        description={t('dashboard.Account Dashboard.welcomeDescription')}
                        img={<WelcomeIcon />}
                        action={
                            <Button
                                href={paths.dashboard.customer.digitalHub}
                                variant="contained"
                                color="primary">
                                {t('dashboard.Account Dashboard.welcomeAction')}
                            </Button>
                        }
                    />
            }

        </Stack>
    )
}