import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { usePathname } from 'src/hooks/use-pathname';
import Logo from 'src/components/logo/logo';
import { useTranslate } from 'src/locales';
import Image from 'src/components/image/image';
// import mada from 'src/assets/payments/mada.svg';
// import visaCard from 'src/assets/payments/visaCard.svg';
// import masterCard from 'src/assets/payments/masterCard.svg';
// import applePay from 'src/assets/payments/applePay.svg';
import { alpha } from '@mui/material';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Pages',
    children: [
      { name: 'Benefits', href: '#Benefits' },
      { name: 'why us?', href: '#About_us' },
      { name: 'Contact us', href: '#Contact_us' },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '/termsOfService' },
      { name: 'Privacy Policy', href: '/privacyPolicy' },
    ],
  },
  {
    headline: 'Contact',
    children: [{ name: 'support@minimals.cc', href: 'mailto:support@wsam.io' }],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {

  const { t } = useTranslate();

  const pathname = usePathname();

  const homePage = pathname === '/';

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography variant="body1" component="div" color="text.secondary">
          {t('landing.footer.copyright')}
          {/* <br /> made by
          <Link href="https://minimals.cc/"> minimals.cc </Link> */}
        </Typography>
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.15),
      }}
    >
      <Divider />

      <Container
        sx={{
          py: { xs: 2, md: 4 },
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Logo
          isNamed={true}
          variant='large'
          sx={{ mb: 3 }}
        />

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={8} md={4}>
            <Typography
              variant="body2"
              sx={{
                maxWidth: 300,
                mx: { xs: 'auto', md: 'unset' },
                mb: { xs: 2, md: 0 },
              }}
            >
              {t('landing.footer.description')}
            </Typography>
          </Grid>

          <Grid xs={12} md={7}>
            <Stack spacing={{ xs: 3, md: 5 }} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list, index) => (
                <Stack
                  key={list.headline}
                  spacing={{ xs: 1, md: 2 }}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="body2">
                    {t(`landing.footer.links.${index}.headline`)}
                  </Typography>

                  {list.children.map((link, linkIndex) => (
                    <Link
                      key={link.name}
                      component={Link}
                      href={link.href.includes('#') ? `/${link.href}` : link.href}
                      color="inherit"
                      variant="caption"
                    >
                      {t(`landing.footer.links.${index}.children.${linkIndex}.name`)}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          sx={{ mt: 4 }}
        >
          <img src={'/assets/payments/mada.svg'} alt="mada" width={55} height={30} style={{ border: '1px solid grey', borderRadius: '4px', padding: "5px" }} />
          <img src={'/assets/payments/visaCard.svg'} alt="visaCard" width={55} height={30} style={{ border: '1px solid grey', borderRadius: '4px', padding: "5px" }} />
          <img src={'/assets/payments/masterCard.svg'} alt="masterCard" width={55} height={30} style={{ border: '1px solid grey', borderRadius: '4px', padding: "5px" }} />
          <img src={'/assets/payments/applePay.svg'} alt="applePay" width={55} height={30} style={{ border: '1px solid grey', borderRadius: '4px', padding: "5px" }} />
          <img src={'https://cdn.salla.network/cdn-cgi/image/fit=scale-down,width=58,height=58,onerror=redirect,format=auto/images/payment/google_pay_mini.png'} alt="googlePay" width={55} height={30} style={{ border: '1px solid grey', borderRadius: '4px', padding: "5px" }} />
        </Stack>

        <Stack direction='row'
          spacing={2}
          justifyContent='center'
          sx={{ mt: 4 }}>
          <a href='https://eauthenticate.saudibusiness.gov.sa/certificate-details/0000135059'>
            <img src={'https://cdn.saudibusiness.gov.sa/v2/dist//images/headerIcons/Logo.svg'} alt="applePay" width={201} height={50} style={{ border: '1px solid grey', borderRadius: '4px', padding: "5px" }} />
          </a>
        </Stack>

        <Typography variant="body2" textAlign={'center'} mt={3}>
          {t('landing.footer.copyright')}
        </Typography>
      </Container>
    </Box>
  );

  return mainFooter;
  // return homePage ? simpleFooter : mainFooter;
}
