import { useMemo } from 'react';


import { useTranslate } from 'src/locales';
import SvgColor from 'src/components/svg-color/svg-color';
import Iconify from 'src/components/iconify/Iconify';
import { paths } from 'src/routes/paths';


// ----------------------------------------------------------------------

// a small function to generate the icons for the nav
const icon = (icon: string) => (
  <Iconify icon={icon} sx={{ width: 1, height: 1 }} />
);
const ICONS = {
  dashboard: icon('uil:tachometer-fast'),
  templates: icon('lucide:package-open'),
  businessTitles: icon('arcticons:jobstreet'),
  allTemplates: icon('ic:twotone-travel-explore'),
  customerCare: icon('ph:cards-three-fill'),
  browseCustomers: icon('tabler:user-cog'),
  signOut: icon('codicon:sign-out'),
  //customer dashboard icons
  createDigitalHub: icon('solar:home-add-bold'),
  myAccount: icon('hugeicons:user-account'),
  support: icon('fluent:person-support-20-filled')
};

// ----------------------------------------------------------------------


// this is the data for the nav in the admin dashboard
export function useNavData() {
  const { t } = useTranslate();

  const data = useMemo(
    () => [
      {

        subheader: t('overview'),
        items: [
          {
            title: t('Account Dashboard'),
            path: paths.dashboard.admin.home,
            icon: ICONS.dashboard,
          }
        ],
      },
      {
        subheader: t('Product Management'),
        items: [
          // {
          //   title: t('Templates'),
          //   path: paths.dashboard.admin.templates,
          //   icon: ICONS.templates
          // },
          {
            title: t('Business titles'),
            path: paths.dashboard.admin.business_title,
            icon: ICONS.businessTitles
          },
          {
            title: t('Templates'),
            path: paths.dashboard.admin.all_templates,
            icon: ICONS.allTemplates
          },
        ],
      },
      {
        subheader: t('Customer Management'),
        items: [
          {

            title: t('Business Cards'),
            path: paths.dashboard.admin.business_cards,
            icon: ICONS.customerCare,
          },
          {
            title: t('Browse Customers'),
            path: paths.dashboard.admin.browse_customers,
            icon: ICONS.browseCustomers,
          }
        ],
      },
      {
        subheader: t('System'),
        items: [
          {
            title: t('Sign out'),
            path: '/dashboard/o',
            icon: ICONS.signOut,
          },
        ],
      }
    ],
    [t]
  );

  return data;
}


// this is the data for the nav in the customer dashboard
export function useNavCustomerDashboard() {
  const { t } = useTranslate();

  const data = useMemo(
    () => [
      {

        subheader: t('dashboard.sideBar.overview'),
        items: [
          {
            title: t('dashboard.sideBar.Account Dashboard'),
            path: paths.dashboard.customer.home,
            icon: ICONS.dashboard,
          }
        ],
      },
      // {
      //   subheader: t('dashboard.sideBar.Product Management'),
      //   items: [
      //     {
      //       title: t('dashboard.sideBar.Create Digital Hub'),
      //       path: paths.dashboard.customer.digitalHub,
      //       icon: ICONS.templates
      //     }
      //   ],
      // },
      {
        subheader: t('dashboard.sideBar.Customer Management'),
        items: [
          {
            title: t('dashboard.sideBar.My Account'),
            path: paths.dashboard.customer.account,
            icon: ICONS.myAccount,
          },
        ],
      }
    ],
    [t]
  );

  return data;
}
