import { Container, Stack } from '@mui/material'


type Props = {
    children: React.ReactNode
}
// this layout is for all the verification pages (VerifyPage , CheckPage....)
export default function VerifyLayout({ children }: Props) {
    return (
        <Container component="main">
            <Stack
                sx={{
                    py: 12,
                    m: 'auto',
                    maxWidth: 400,
                    minHeight: '100vh',
                    textAlign: 'center',
                    justifyContent: 'center',
                }}
            >
                {children}
            </Stack>
        </Container>
    )
}
