import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { HEADER, NAV, useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { bgBlur } from 'src/theme/css';
import { useSettingsContext } from 'src/components/settings';
import Logo from 'src/components/logo/logo';
import LanguagePopover from '../common/language-popover';
import Iconify from 'src/components/iconify/Iconify';
import { Typography } from '@mui/material';
import { useTranslate } from 'src/locales';



// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};


// this is the header for customer dashboard 
export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const { t } = useTranslate()


  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {
        lgUp &&
        <Typography
          variant='h3'
          color='grey.600'
          flexGrow={1}
        >
          {t('dashboard.header.header')}
        </Typography>
      }

      {!lgUp && (
        <IconButton
          size='large'
          onClick={onOpenNav}
        >
          <Iconify icon='ic:baseline-menu' />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <LanguagePopover />
        {/* <Avatar
          alt="customer"
          sx={{ bgcolor: 'primary.main', width: 32, height: 32 }}
        >
          {!!decodedToken?.Role ? user?.fullname[0] : user?.firstName[0]}
        </Avatar> */}

        {/* <Button
          variant='outlined'
          onClick={handleLogout}
          endIcon={<Iconify icon='humbleicons:logout' />}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'primary.main' }}
        >
          {t('dashboard.header.logout')}
        </Button> */}
      </Stack>
    </>
  );

  return (
    <AppBar
      elevation={0}
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar

        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
