import { alpha, Box, Paper, Stack, Typography } from '@mui/material';
import React from 'react'
import Logo from 'src/components/logo/logo';
// import login_img from 'src/assets/login_wsam.png'
import { useResponsive } from 'src/hooks/use-responsive';

type Props = {
    children: React.ReactNode,
    image?: string,
    title?: string,
}

// this layout is for the auth pages (login, register...)
export default function AuthLayout({ children, image, title }: Props) {
    const mdUp = useResponsive('up', 'md')
    const renderLogo = (
        <Logo
            sx={{
                position: 'absolute',
                m: { xs: 2 }
            }}
        />
    )
    const renderSection = (
        <Stack
            flexGrow={1.2}
            alignItems='center'
            justifyContent='center'
            spacing={2}
            mt={5}
        >
            <Box
                component='img'
                alt='login_wsam'
                src={image || "/assets/home/logo_wsam.png"}
                sx={{
                    maxWidth: {
                        xs: 480,
                        lg: 550,
                        xl: 690,
                    },
                }} />
        </Stack>
    )

    const renderContent = (
        <Stack
            component={mdUp ? Paper : Stack}
            variant='outlined'
            // {
            // ...(mdUp ? { elevation: 2 } : {})
            // }
            sx={{
                width: { xs: '95%', sm: '70%', md: '60%', lg: '45%' },
                mx: 'auto',
                my: 'auto',
                px: { xs: 2, md: 6 },
                py: 3
            }}
        >
            {children}
        </Stack>
    );
    return (
        <Stack
            component='main'
            direction='row'
            sx={{
                minHeight: '100vh',
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
            }}
        >
            {renderLogo}
            {/* {mdUp && renderSection} */}
            {renderContent}

        </Stack>
    )
}
