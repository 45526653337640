import merge from 'lodash/merge';
import {
  enUS as enUSCore,
  arSA as arSACore,
} from '@mui/material/locale';


export const allLangs = [
  {
    //label: 'English',
    label: 'العربية',
    value: 'en',
    systemValue: merge(enUSCore),
    icon: 'flagpack:gb-nir',
  },
  {
    //label: 'العربية',
    label: 'English',
    value: 'ar',
    systemValue: merge(arSACore),
    icon: 'flagpack:sa',
  },
];

export const defaultLang = allLangs[0]; // English