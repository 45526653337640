enum FontFamily {
    arabic = 'Noto Sans Arabic, sans-serif',
    english = 'Quicksand, sans-serif',
}

export default function getFontFamily(lang: string): string {

    switch (lang) {
        case 'ar':
            return FontFamily.arabic;
        case 'en':
            return FontFamily.english;
        default:
            return FontFamily.english;
    }

}